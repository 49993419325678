export default class Navigation {
  constructor() {
    this.cutoutLeft = $('.cutout-bar__left');
    this.headerNavItems = $("header li a:contains('My')");

    $('header nav a').hover((e) => {
      const $target = $(e.currentTarget);
      const selectedLinkCenter = $target.offset().left + ($target.outerWidth() / 2) - 28;

      this.cutoutLeft.css('width', selectedLinkCenter);
    }, () => {
      this.cutoutLeft.css('width', this.activeWidth);
    });

    $(window).on('load resize', () => {
      this.initMobileNav();
      this.initIndicator();
    });
  }

  initIndicator() {
    const activeLink = $('header nav .active, header nav .active-phone');
    const activeLinkCenter = activeLink.offset().left + (activeLink.outerWidth() / 2) - 28;

    this.activeWidth = activeLinkCenter;
    this.cutoutLeft.css('width', activeLinkCenter);

    if (!$('.cutout-bar__cutout').hasClass('loaded')) {
      setTimeout(() => {
        $('.cutout-bar__cutout').addClass('loaded');
      }, 400);
    }
  }

  initMobileNav() {
    const windowWidth = $(window).width();

    this.headerNavItems.each((index, item) => {
      const $item = $(item);

      if (windowWidth < 740) {
        let linkText = $item.text();

        if (linkText.match(/My/)) {
          linkText = linkText.replace('My ', '');
          $item.text(linkText);
        }
      } else {
        let linkText = $item.text();

        if (!linkText.match(/My/)) {
          $item.text(`My ${linkText}`);
        }
      }
    });
  }
}
