export default class Work {
  constructor() {
    this.spinner = $('.spinner');
    this.spinner.css('display', 'block');

    $(window).on('load', () => {
      $('.content__body').addClass('loaded');
      this.spinner.css('display', 'none');
    });
  }
}