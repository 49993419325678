import Vimeo from '@vimeo/player';

export default class Video {
  constructor() {
    if ($('.video').length > 0) {
      // Find all videos
      var allVideos = $("iframe[src^='//player.vimeo.com'], iframe[src^='//www.youtube.com'], video");

      // The element that is fluid width
      var fluidEl = $(".video__file");

      // Figure out and save aspect ratio for each video
      allVideos.each(function() {
        if ($(this).is('iframe')) {
          $(this)
            .data('aspectRatio', $(this).outerHeight() / this.clientWidth)

            // and remove the hard coded width/height
            .removeAttr('height')
            .removeAttr('width');
        } else {
          $(this)
            .data('aspectRatio', this.height / this.width)

            // and remove the hard coded width/height
            .removeAttr('height')
            .removeAttr('width');
        }
      });

      // When the window is resized
      $(window).resize(function() {
        var newWidth = fluidEl.width();
        // Resize all videos according to their own aspect ratio
        allVideos.each(function() {

          var el = $(this);
          el
            .width(newWidth)
            .height(newWidth * el.data('aspectRatio'));
        });
      // Kick off one resize to fix all videos on page load
      }).resize();
    }
  }
}