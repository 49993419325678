import '@babel/polyfill';
import objectFitImages from 'object-fit-images';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

objectFitImages();

import HomepageMessage from './homepage-message';
import Navigation from './navigation';
import SideNav from './side-nav';
import Tabs from './tabs';
import Video from './video';
import Work from './work';

new HomepageMessage();
new Navigation();
new SideNav();
new Tabs();
new Video();
new Work();
