export default class Tabs {
  constructor() {
    this.tabs = $('.tab');
    this.content = $('.tab-content');

    this.tabs.find('button').on('click', (e) => {
      const $target = $(e.currentTarget),
        tab = $target.parent(),
        tabIndex = tab.index();

      const workContent = $target.parents('.work__tabs').find('.tab-content');
      workContent.removeClass('active');

      if (tab.hasClass('active')) {
        tab.removeClass('active');
      } else {
        tab.siblings().removeClass('active');
        tab.addClass('active');

        const targetedContent = workContent.eq(tabIndex);
        targetedContent.addClass('active');
      }
    });

    $('.tab-content__close').on('click', (e) => {
      const $target = $(e.currentTarget),
        content = $target.parent();

      content.removeClass('active');

      const workTabs = content.parents('.work__tabs').find('.tab');
      workTabs.removeClass('active');
    });
  }
}