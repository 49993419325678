import './vendor/jquery.fittext';
import './teletype';

export default class HomepageMessage {
  constructor() {
    $('.homepage-text').fitText(0.7, {
      maxFont: '126px'
    });

    this.wordContainers = $('.homepage-text span .homepage-text__line');
    this.initTeletype();
  }

  initTeletype() {
    const wordLength = this.wordContainers.length;
    let picked = Math.floor(Math.random() * wordLength);
    let words = [];

    this.wordContainers.each((index, word) => {
      const $word = $(word),
        cleaned = $word.text().replace(/\s+/g, ' ');

      words.push(cleaned);

      $word.addClass('show');
      $word.html('<span id="target"></span><span id="cursor"></span>');
    });

    const firstWord = $('.homepage-text span .homepage-text__line:first-child');

    firstWord.find('#target').teletype({
      text: words
    });
    firstWord.find('#cursor').teletype({
      text: ['_', ' '],
      delay: 100,
      pause: 400
    });
  }
}

