export default class SideNav {
  constructor() {
    this.elements = $('.work, .process');

    this.initSideNav();
    this.initScrollAnimation();
  }

  initSideNav() {
    if (this.elements.length > 1) {
      $('.content').append('<div class="side-nav"><ul></ul></div>');
      const sideNav = $('.content .side-nav ul');

      this.elements.each((_, element) => {
        const $element = $(element);

        if ($element.hasClass('work')) {
          const tooltipText = $element.find('> h1').text();
          sideNav.append((`<li><button></button><div class="tooltip">${tooltipText}</div></li>`));
        } else {
          sideNav.append(('<li><button></button></li>'));
        }
      });

      $(window).on('load resize scroll', () => {
        let activeItems = [];

        this.elements.each((_, element) => {
          const elementPosition = element.getBoundingClientRect();

          if (elementPosition.top < $(window).height() / 2) {
            activeItems.push(element);
          }
        });

        const lastActive = activeItems.slice(-1).pop(),
          activeIndex = $(lastActive).index();

        sideNav.find('li').removeClass('active');
        sideNav.find('li').eq(activeIndex).addClass('active');
      });

      sideNav.find('li, button').on('click', (e) => {
        const $target = $(e.currentTarget);
        let targetIndex = 0;

        if ($target.is('li')) {
          targetIndex = $target.index();
        } else {
          targetIndex = $target.parent().index();
        }

        $('html, body').animate({ scrollTop: this.elements.eq(targetIndex).offset().top - 100 }, 600);
      });
    }
  }

  initScrollAnimation() {
    $(window).on('load resize scroll', (e) => {
      const windowHeight = $(window).height();

      this.elements.each((_, element) => {
        const elementPosition = element.getBoundingClientRect();

        if (elementPosition.bottom < 175 || elementPosition.top + 175 > windowHeight) {
          $(element).addClass('fade');
        } else {
          $(element).removeClass('fade');
        }
      });
    });
  }
}